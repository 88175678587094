import { IconButton } from '@mui/material'
import { MaterialReactTable } from 'material-react-table'
import { useEffect, useRef, useState } from 'react'
import { FaPerson } from "react-icons/fa6"
import { IoMdAddCircleOutline } from "react-icons/io"
import { MdAssignmentAdd } from "react-icons/md"
import { RiDeleteBin2Line } from 'react-icons/ri'
import { useModifiedMaterialReactTable } from '../misc/modified_react_table'
import { useAllClientSummariesLookup } from '../processes/all_client_summaries_lookup'
import { useAllEmissionCategorySummariesLookup } from '../processes/all_emission_category_summaries_lookup'
import { useAllEmissionFactorSummariesLookup } from '../processes/all_emission_factor_summaries_lookup'
import { useClientAvatarUpload, useClientAvatarUploadState } from '../processes/client_avatar_upload'
import { useClientEmissionAssignment, useClientEmissionAssignmentState } from '../processes/client_emission_assignment'
import { useClientRegistration, useClientRegistrationState } from '../processes/client_registration'
import moment from 'moment'

export default function Clients() {
  const registrationDialog = useRef()
  const {
    isPending: clientRegistrationIsInProgress,
  } = useClientRegistrationState()
  const {
    isPending: clientEmissionAssignmentIsInProgress,
  } = useClientEmissionAssignmentState()
  const {
    isPending: clientAvatarUploadIsInProgress,
  } = useClientAvatarUploadState()
  const [
    clientSummaryForEmissionAssignment,
    setClientSummaryForEmissionAssignment,
  ] = useState()
  const [
    clientSummaryForAvatarUpload,
    setclientSummaryForAvatarUpload,
  ] = useState()

  const {
    data: allClientSummaries,
    isLoading: isLoadingAllUserSummaries,
    isFetching: isFetchingAllUserSummaries,
  } = useAllClientSummariesLookup()
  const allClientSummariesTable = useModifiedMaterialReactTable({
    data: allClientSummaries,
    columns: [
      {
        id: 'id',
        header: 'Id',
        accessorKey: 'clientId',
        Cell: ({ row, cell }) => {
          return <span
            style={{
            }}
          >
            {cell.getValue()}
          </span>
        },
      },
      {
        id: 'name',
        header: 'Name',
        accessorFn: (row) => {
          return row.clientName
        },
        Cell: ({ row, cell }) => {
          return <span
            style={{
              fontWeight: 'bold',
            }}
          >
            {cell.getValue()}
          </span>
        },
      },
      {
        id: 'employees',
        header: 'Employees',
        accessorKey: 'clientEmployees',
      },
      {
        id: 'subscription',
        header: 'Subscription',
        accessorKey: 'clientSubscription',
      },
      {
        id: 'expiration',
        header: 'Expiration',
        accessorFn: (row) => {
          return moment(row.expiration).format('YYYY-MM-DD')
        },
        Cell: ({ row, cell }) => {
          return <span
            style={{
              fontWeight: 'bold',
            }}
          >
            {cell.getValue()}
          </span>
        },
      },
    ],
    getRowId: (row) => {
      return row.clientId
    },
    enablePagination: true,
    state: {
      isLoading: isLoadingAllUserSummaries,
      showProgressBars: isFetchingAllUserSummaries ||
        clientRegistrationIsInProgress ||
        clientAvatarUploadIsInProgress ||
        clientEmissionAssignmentIsInProgress,
      isSaving: clientRegistrationIsInProgress ||
        clientAvatarUploadIsInProgress ||
        clientEmissionAssignmentIsInProgress,
    },
    renderTopToolbarCustomActions: () => {
      return <span
        className={[
          'tw-flex-1',
          'tw-flex',
          'tw-items-center',
        ].join(' ')}
      >
        <IconButton
          onClick={() => {
            registrationDialog?.current?.showModal()
          }}
        >
          <IoMdAddCircleOutline className='tw-text-2xl' />
        </IconButton>
      </span>
    },
    // enableRowSelection: false,
    enableRowActions: true,
    renderRowActions: ({ table, row }) => {
      return [
        <IconButton
          key={`row?.id`}
          title="Avatar Upload"
          onClick={() => {
            setclientSummaryForAvatarUpload(row.original)
          }}
        >
          <FaPerson fontSize={'1.5rem'} />
        </IconButton>,
        <IconButton
          key={row?.id}
          title="Emission Assignment"
          onClick={() => {
            setClientSummaryForEmissionAssignment(row.original)
          }}
        >
          <MdAssignmentAdd fontSize={'1.5rem'} />
        </IconButton>
      ]
    },
  })

  return <div
    className={[
      'tw-flex-1',
      'tw-flex',
      'tw-flex-col',
      'tw-items-stretch',
      'tw-p-7',
    ].join(' ')}
  >
    <MaterialReactTable
      table={allClientSummariesTable}
    />
    <ClientRegistrationDialog
      dialog={registrationDialog}
    />
    <ClientEmissionAssignmentDialog
      clientSummary={clientSummaryForEmissionAssignment}
      onClose={() => setClientSummaryForEmissionAssignment()}
    />
    <ClientAvatarUploadDialog
      clientSummary={clientSummaryForAvatarUpload}
      onClose={() => setclientSummaryForAvatarUpload()}
    />
  </div>
}

function ClientRegistrationDialog({ dialog }) {
  const {
    isSuccess: clientRegistrationHasSucceeded,
    mutate: clientRegistration,
  } = useClientRegistration()
  useEffect(() => {
    if (clientRegistrationHasSucceeded) {
      dialog.current.close()
    }
  }, [clientRegistrationHasSucceeded, dialog])
  const [clientName, setClientName] = useState('')

  return <dialog
    ref={dialog}
    id='client-registration'
    className='tw-modal tw-modal-bottom sm:tw-modal-middle'
    onKeyDown={(event) => {
      if (event.key === 'Escape') {
        event.stopPropagation()
      }
    }}
    onClose={(event) => {
      setClientName('')
    }}
  >
    <div className='tw-modal-box tw-border tw-border-[revert] tw-p-0 tw-flex tw-flex-col'>
      <div
        className={[
          'tw-font-semibold',
          'tw-bg-primary',
          'tw-text-primary-content',
          'tw-p-4',
          'sm:tw-p-5',
          'tw-flex',
          'tw-items-center',
        ].join(' ')}
      >
        Client Registration
      </div>
      <div className={[
        'tw-px-4',
        'sm:tw-px-5',
        'tw-pb-4',
        'sm:tw-pb-5',
        'tw-overflow-y-auto',
      ].join(' ')}>
        <div className='tw-form-control tw-w-full'>
          <label className='tw-label'>
            <span className='tw-label-text'>
              Name
            </span>
          </label>
          <input
            type='text'
            placeholder='/ Type Here'
            className={[
              'tw-input',
              'tw-input-bordered',
              'tw-input-primary',
              'tw-w-full',
            ].join(' ')}
            value={clientName}
            onChange={(event) => {
              setClientName(event.target.value)
            }}
          />
        </div>
        <div className='tw-pt-4'>
          <button
            type='button'
            className='tw-btn tw-btn-primary tw-w-full'
            onClick={() => {
              clientRegistration({
                clientName,
              })
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
    <form method='dialog' className='tw-modal-backdrop'>
      <button>close</button>
    </form>
  </dialog>
}

function ClientEmissionAssignmentDialog({
  clientSummary,
  onClose,
}) {
  const {
    data: emissionFactorSummaries,
  } = useAllEmissionFactorSummariesLookup()
  const {
    data: emissionCategorySummaries,
  } = useAllEmissionCategorySummariesLookup()
  const [emissions, setEmissions] = useState([])
  useEffect(() => {
    setEmissions(clientSummary?.emissions ?? [])
  }, [clientSummary])
  const emissionSummaries = emissionFactorSummaries
    ?.reduce((previous, current) => {
      const emission = emissions.find(emission => {
        return emission.factorId === current.emissionFactorId
      })
      if (emission) {
        previous[0].push({
          factor: current,
          category: emissionCategorySummaries.find(category => {
            return category.categoryId === emission.categoryId
          })
        })
      } else {
        previous[1].push({
          factor: current
        })
      }
      return previous
    }, [[], []])
    ?.reduce((previous, current) => {
      return previous.concat(current)
    })
  const [search, setSearch] = useState('')
  const searchedEmissionSummaries = emissionSummaries?.filter(({ factor, category }) => {
    return factor?.emissionName?.toLowerCase()?.includes(search?.toLowerCase()) ||
      category?.categoryName?.toLowerCase()?.includes(search?.toLowerCase())
  })


  const emissionAssignmentDialog = useRef()
  const {
    isSuccess: clientEmissionAssignmentHasSucceeded,
    mutate: clientEmissionAssignment,
  } = useClientEmissionAssignment()
  useEffect(() => {
    if (clientEmissionAssignmentHasSucceeded) {
      emissionAssignmentDialog.current.close()
    }
  }, [clientEmissionAssignmentHasSucceeded])
  useEffect(() => {
    if (clientSummary) {
      emissionAssignmentDialog.current.showModal()
    } else {
      emissionAssignmentDialog.current.close()
    }
  }, [clientSummary])

  const [position, setPosition] = useState(0)
  useEffect(() => {
    setPosition(0)
  }, [search])
  const option = useRef()
  useEffect(() => {
    option.current?.scrollIntoView({
      behavior: 'instant',
      block: 'nearest',
    })
  }, [position])
  const optionRemoval = useRef()
  const [
    categoryChangeHandler,
    setCategoryChangeHandler,
  ] = useState()
  const searchBox = useRef()
  useEffect(() => {
    searchBox.current?.focus()
    searchBox.current?.select()
  }, [categoryChangeHandler])

  return <>
    <dialog
      ref={emissionAssignmentDialog}
      className={[
        'tw-modal',
        'tw-modal-bottom',
        'sm:tw-modal-middle',
      ].join(' ')}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          event.stopPropagation()
        } else if (
          event.key === 'ArrowDown' &&
          position + 1 < searchedEmissionSummaries?.length
        ) {
          setPosition(position + 1)
        } else if (
          event.key === 'ArrowUp' &&
          position - 1 > -1
        ) {
          setPosition(position - 1)
        } else if (
          event.key === 'Enter' &&
          searchedEmissionSummaries?.length
        ) {
          option.current?.click()
        } else if (
          event.key === 'Delete' &&
          searchedEmissionSummaries?.length
        ) {
          optionRemoval.current?.click()
        }
      }}
      onClose={onClose}
    >
      <div className={[
        'tw-modal-box',
        'tw-border',
        'tw-border-[revert]',
        'tw-p-0',
        'tw-flex',
        'tw-flex-col',
        'tw-h-full',
      ].join(' ')}>
        <div className={[
          'tw-font-semibold',
          'tw-bg-primary',
          'tw-text-primary-content',
          'tw-px-4',
          'sm:tw-px-5',
          'tw-py-1',
          'sm:tw-py-2',
          'tw-flex',
          'tw-items-center',
        ].join(' ')}>
          <input
            ref={searchBox}
            placeholder='/ Type Something'
            className={[
              'tw-input',
              'tw-bg-primary',
              'tw-text-primary-content',
              'tw-font-semibold',
              'placeholder:tw-text-primary-content',
              'placeholder:tw-font-semibold',
              'tw-w-full',
              'tw-p-0',
              'focus:tw-outline-none',
            ].join(' ')}
            value={search}
            onChange={(event) => {
              setSearch(event.target.value ?? '')
            }}
          />
        </div>
        <div className={[
          'tw-overflow-y-auto',
          'tw-h-full',
        ].join(' ')}>
          <div className='tw-flex tw-flex-col'>
            {searchedEmissionSummaries?.map(({ factor, category }, index) => {
              return <div key={index} className='tw-flex'>
                <div className='tw-flex-1 tw-flex tw-flex-col tw-relative'>
                  <button
                    ref={index === position ? option : null}
                    placeholder={'/ Emission Factor'}
                    className={[
                      'tw-btn',
                      'tw-btn-primary',
                      'tw-no-animation',
                      'tw-transition-none',
                      'tw-justify-start',
                      'tw-border-t-0',
                      index === position ? '' : 'tw-btn-outline',
                    ].join(' ')}
                    onClick={() => {
                      setCategoryChangeHandler(() => {
                        return (newCategory) => {
                          if (category) {
                            setEmissions(emissions?.map(emission => {
                              if (
                                emission.factorId === factor.emissionFactorId
                              ) {
                                return {
                                  factorId: factor.emissionFactorId,
                                  categoryId: newCategory.categoryId,
                                }
                              } else {
                                return emission
                              }
                            }))
                          } else {
                            setEmissions(emissions.concat([{
                              factorId: factor.emissionFactorId,
                              categoryId: newCategory.categoryId,
                            }]))
                          }
                        }
                      })
                    }}
                  >
                    {factor.emissionName} ({factor.emissionFactorId})
                  </button>
                  {!!category && <div className={[
                    'tw-badge',
                    'tw-badge-primary',
                    'tw-absolute',
                    'tw-top-0',
                    'tw-right-0',
                    'tw-text-xs',
                  ].join(' ')}>
                    {category.categoryName} ({category.categoryId})
                  </div>}
                </div>
                {!!category && <button
                  ref={index === position ? optionRemoval : null}
                  className={[
                    'tw-flex',
                    'tw-justify-center',
                    'tw-items-center',
                    'tw-p-2',
                    'tw-border',
                    'tw-border-l-0',
                    'tw-border-t-0',
                  ].join(' ')}
                  onClick={() => {
                    setEmissions(emissions?.filter(emission => {
                      return emission.factorId !== factor.emissionFactorId
                    }))
                  }}
                >
                  <RiDeleteBin2Line className='tw-text-2xl' />
                </button>}
              </div>
            })}
          </div>
        </div>
        {!!searchedEmissionSummaries?.length && <div>
          <button
            type='button'
            className='tw-btn tw-btn-primary tw-w-full'
            onClick={() => {
              clientEmissionAssignment({
                clientId: clientSummary.clientId,
                emissions,
              })
            }}
          >
            Submit
          </button>
        </div>}
      </div>
      <form method='dialog' className='tw-modal-backdrop'>
        <button>close</button>
      </form>
    </dialog>
    <FilterableSelectDialog
      idKey='categoryId'
      nameKey='categoryName'
      values={emissionCategorySummaries}
      open={!!categoryChangeHandler}
      onChange={(value) => {
        categoryChangeHandler(value)
        setCategoryChangeHandler()
        setSearch('')
        setPosition(0)
      }}
      onClose={() => {
        setCategoryChangeHandler()
      }}
    />
  </>
}

function ClientAvatarUploadDialog({
  clientSummary,
  onClose,
}) {
  const [file, setFile] = useState()
  const [fileUrl, setFileUrl] = useState()
  useEffect(() => {
    if (!file) {
      setFileUrl()
    } else {
      const url = URL.createObjectURL(file)
      setFileUrl(url)
      return () => URL.revokeObjectURL(url)
    }
  }, [file])

  const fileBox = useRef()
  useEffect(() => {
    if (!file) fileBox.current.value = null
  })

  const avatarUploadDialog = useRef()
  useEffect(() => {
    if (clientSummary) {
      avatarUploadDialog.current.showModal()
    } else {
      avatarUploadDialog.current.close()
    }
  }, [clientSummary])

  const {
    isSuccess: clientAvatarUploadHasSucceeded,
    mutate: clientAvatarUpload,
  } = useClientAvatarUpload()
  useEffect(() => {
    if (clientAvatarUploadHasSucceeded) {
      avatarUploadDialog.current.close()
    }
  }, [clientAvatarUploadHasSucceeded])

  return <dialog
    ref={avatarUploadDialog}
    className='tw-modal tw-modal-bottom sm:tw-modal-middle'
    onKeyDown={(event) => {
      if (event.key === 'Escape') {
        event.stopPropagation()
      }
    }}
    onClose={event => {
      setFile()
      onClose(event)
    }}
  >
    <div className='tw-modal-box tw-border tw-border-[revert] tw-p-0 tw-flex tw-flex-col'>
      <div
        className={[
          'tw-font-semibold',
          'tw-bg-primary',
          'tw-text-primary-content',
          'tw-p-4',
          'sm:tw-p-5',
          'tw-flex',
          'tw-items-center',
        ].join(' ')}
      >
        Client Avatar Upload
      </div>
      <div id='assigned-emissions' className={[
        'tw-px-4',
        'sm:tw-px-5',
        'tw-pb-4',
        'sm:tw-pb-5',
        'tw-overflow-y-auto',
      ].join(' ')}>
        <div className='tw-form-control tw-w-full'>
          <div className='tw-label'>
            {/* <span className='label-text'>
              Avatar
            </span> */}
          </div>
          <div className='tw-flex tw-gap-3 tw-items-center'>
            <div className='tw-flex-1'>
              <input
                ref={fileBox}
                type="file"
                accept='image/*'
                className={[
                  'tw-file-input',
                  'tw-file-input-bordered',
                  'tw-w-full',
                ].join(' ')}

                onChange={(event) => {
                  if (!event.target.files?.length) {
                    setFile()
                  } else {
                    setFile(event.target.files[0])
                  }
                }}
              />
            </div>
            <div className="tw-avatar">
              <div className="tw-w-16 tw-border">
                {clientSummary && <img
                  alt=''
                  src={!fileUrl ? [
                    process.env.REACT_APP_API_URL,
                    '/uploads/client/',
                    clientSummary?.clientImage,
                  ].join('') : fileUrl}
                />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type='button'
        className='tw-btn tw-btn-primary tw-w-full'
        onClick={() => {
          clientAvatarUpload({
            clientId: clientSummary.clientId,
            avatar: file,
          })
        }}
      >
        Submit
      </button>
    </div>
    <form method='dialog' className='tw-modal-backdrop'>
      <button>close</button>
    </form>
  </dialog>
}

function FilterableSelectDialog({
  idKey,
  nameKey,
  values,
  open,
  onChange,
  onClose,
}) {
  const [search, setSearch] = useState('')
  const searchedValues = values?.filter(value => {
    const name = value[nameKey]
    return name.toLowerCase().includes(search.toLowerCase())
  })
  const dialog = useRef()
  useEffect(() => {
    open && dialog.current.showModal()
  }, [open])
  const [position, setPosition] = useState(0)
  useEffect(() => {
    setPosition(0)
  }, [search])
  const option = useRef()
  useEffect(() => {
    option.current?.scrollIntoView({
      behavior: 'instant',
      block: 'nearest',
    })
  }, [position])

  return <>
    {open && <dialog
      ref={dialog}
      className='tw-modal tw-modal-bottom sm:tw-modal-middle'
      onKeyDown={(event) => {
        event.stopPropagation()
        if (
          event.key === 'ArrowDown' &&
          position + 1 < searchedValues?.length
        ) {
          setPosition(position + 1)
        } else if (
          event.key === 'ArrowUp' &&
          position - 1 > -1
        ) {
          setPosition(position - 1)
        } else if (
          event.key === 'Enter' &&
          searchedValues?.length
        ) {
          setSearch('')
          onChange(searchedValues[position])
        }
      }}
      onClose={(event) => {
        event.stopPropagation()
        setSearch('')
        onClose(event)
      }}
    >
      <div className={[
        'tw-modal-box',
        'tw-border',
        'tw-p-0',
        'tw-flex',
        'tw-flex-col',
        'tw-h-full',
      ].join(' ')}>
        <div className={[
          'tw-px-4',
          'sm:tw-px-5',
          'tw-py-1',
          'sm:tw-py-2',
          'tw-bg-primary',
        ].join(' ')}>
          <input
            type='text'
            placeholder='/ Type Something'
            className={[
              'tw-input',
              'tw-bg-primary',
              'tw-text-primary-content',
              'tw-font-semibold',
              'placeholder:tw-text-primary-content',
              'placeholder:tw-font-semibold',
              'tw-w-full',
              'tw-p-0',
              'focus:tw-outline-none',
            ].join(' ')}
            value={search}
            onChange={(event) => {
              setSearch(event.target.value)
            }}
          />
        </div>
        <div
          id='dialog-body'
          className={[
            'tw-overflow-y-auto',
          ].join(' ')}
        >
          <div className='tw-flex-1 tw-flex tw-flex-col'>
            {searchedValues?.map((value, index) => {
              return <button
                ref={index === position ? option : null}
                className={[
                  'tw-flex-1',
                  'tw-btn',
                  'tw-btn-primary',
                  'tw-no-animation',
                  'tw-transition-none',
                  'tw-border-t-0',
                  'tw-border-x-0',
                  'tw-justify-start',
                  'focus:tw-outline-none',
                  'tw-px-3',
                  'sm:tw-px-4',
                  index === position ? '' : 'tw-btn-outline',
                ].join(' ')}
                key={value[idKey]}
                onClick={() => {
                  setSearch('')
                  onChange(value)
                }}
              >
                {value[nameKey]} ({value[idKey]})
              </button>
            })}
          </div>
        </div>
      </div>
      <form method='dialog' className='tw-modal-backdrop'>
        <button>close</button>
      </form>
    </dialog >}
  </>
}
